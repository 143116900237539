import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import './404.scss'

const NotFound = () => (
  <Layout>
    <Seo title="Page not found" />
    <main className="NotFound">
      <h2>Page not found</h2>
    </main>
  </Layout>
)

NotFound.propTypes = {}

export default NotFound
